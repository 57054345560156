@import '../../../../../../assets/scss/bootstrap-helpers';

.Container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 3rem;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Content {
  -webkit-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
  }
}

.BlockContent {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
	cursor:pointer;
}


.BlockName {
  text-align: center;
  padding: 2rem 0 1rem 0;
  font-weight: normal;
  font-size: 2rem;
}

.Horizontal {
  display: grid;
  grid-template-columns:repeat(auto-fit, minmax(300px , 1fr));
}


@include media-breakpoint-only(xs) {
  .Container {
    padding: 0.5rem;
  }

  .BlockContent {
    grid-auto-flow: row;
  }
}

.contentBorder, .elementBorder {
	position:relative;
/*	
	padding:10px !important;
	margin:10px !important;
	border:solid 4px #0000ff;
*/	
}

.moveContentBtn {
	z-index:100;
	position:absolute;
	top:4px;
	left:4px;
	display:none;

	cursor: move;
	color: #BDBDBD;
	margin: 0 1rem 0 0.5rem;
}

.editContentBtn {
	z-index:100;
	xposition:absolute;
	top:4px;
	right:4px;
	display:none;
}

.editContentBtnLeft {
	z-index:100;
	xposition:absolute;
	top:4px;
	left:4px;
	display:none;
}

.editContentBtnHeader {
	background:rgba(0,0,0,0.0);

	position:absolute;	
	width:100%;
	z-index:10;
	padding:5px;
	display:none;
	pointer-events:none;
}
.editContentBtnHeader button {
	pointer-events:all;
}

.editContentBtnHeaderLeft {
	width:50%;
	display:inline-block;
		text-align:left;
}
.editContentBtnHeaderRight {
	width:50%;
	text-align:right;
	display:inline-block;
}

.elementBorder:hover .editContentBtnHeader,
.elementBorder:hover .editContentBtn,
.elementBorder:hover .editContentBtnLeft,
.elementBorder:hover .moveContentBtn {
	display:block;
}


.elementBorder:hover {
	outline:solid 2px #000;
	outline-offset:-2px;
}


.contentBorder:hover {
	outline:solid 2px #000;
	outline-offset:-2px;

}

.editContentBtn *,
.editContentBtnLeft * {
	display:inline-block;
	margin:0 !important;
	color:#ffffff !important;
}

.moveContentBtn > div {
	margin:0 !important;
	color:#ffffff !important;
}

.addElement {
	width:100%;
	padding:10px;
	text-align:center;
	border:dashed 3px #ddd;
}

.hoverInput {	
	text-align:center;
	width:100%;
	border:none;
	padding:0;
	margin:0;
	outline: unset; //1px dashed #ddd;
	background:rgba(0,0,0,0); //255,0.1);
	color:inherit;
}

.xhoverswitch h3 input {
	display:none;
	text-align:center;
	width:100%;
	border:none;
	padding:0;
	margin:0;
	outline: 3px dashed #ddd;
	background:rgba(0,0,255,0.1);
}

.xhoverswitch:hover h3 span {
	display:none;
}

.xhoverswitch:hover h3 input {
	display:inline-block;
}

.hitop {
	cursor:e-resize;
}

.activeBlock {
	outline:solid 4px #000 !important;
	outline-offset:-4px !important;
}

.emptyContent {
	padding:50px;
	color:#000000 !important;
	font-size:2em;
	text-align:center;
	background:#dddddd !important;
	cursor:pointer;
}
